import classNames from 'classnames';
import React, { useEffect } from 'react';

import { t } from '@/utility/localization';

import { useAuthContext } from '@/contexts/AuthContext';

import Icon from '@/components/npl/Icon';

import AvatarCommentBox from '../Announcements/AvatarCommentBox';
import CommentCard from '../Announcements/CommentCard';
import CommentCardLoading from '../Announcements/CommentCardLoading';
import usePostComment from '../Feed/hooks/usePostComment';

const CommentSection = ({
  post,
  community,
  updateAnnouncementInfo,
  hasStickyCommentInput = true,
  readOnly = false,
  commentCount = 0,
  commentInputRef
}) => {
  const { user, isAdmin } = useAuthContext();

  const { _id: communityId } = community || {};

  const { _id: postId } = post;
  const {
    replyingToCommentData,
    setReplyingToCommentData,

    submitLoadingMap,
    deleteLoadingMap,
    textFieldValue,
    onTextFieldChange,

    getCommentInfo,
    onCommentSubmit,
    onCommentDelete,
    fetchMoreComments,

    fetchComments,
    fetchReplies,
    getReplyInfo,
    onReplySubmit,
    onReplyDelete,
    fetchMoreReplies
  } = usePostComment({
    user: user,
    activeCommunity: community
  });

  useEffect(() => {
    // Only call api if commentCount is greater than 0
    if (commentCount > 0) {
      fetchComments({ postId });
    }
  }, [postId, commentCount]);

  // post comment info
  const selectedComment = getCommentInfo({ postId });
  const commentData = selectedComment?.commentInfo;
  const commentList = selectedComment?.commentList ?? [];
  const isCommentLoading =
    commentList?.length > 0 ? selectedComment?.isCommentLoading : false;
  1;

  const haveComments = commentCount > 0;

  const isReplying = Boolean(replyingToCommentData?._id);

  const handleCommentSubmit = () => {
    if (isReplying) {
      const {
        parentCommentObjectId,
        _id: commentId,
        replyCount,
        type,
        rootCommentObjectId
      } = replyingToCommentData || {};
      const replyInfo = getReplyInfo({ commentId });

      onReplySubmit({
        postId,
        commentId: type === 'reply' ? rootCommentObjectId : commentId,
        parentCommentId: parentCommentObjectId ?? commentId,
        shouldFetchReply: replyCount > 0 && !replyInfo?.replyList?.length,
        onSuccess: () => {
          updateAnnouncementInfo({
            post,
            updatedInfo: {
              commentCount: commentCount + 1
            }
          });
        }
      });
      return;
    }

    onCommentSubmit({
      postId,
      shouldFetchComment: commentCount > 0 && !commentList?.length,
      onSuccess: () => {
        updateAnnouncementInfo({
          post,
          updatedInfo: {
            commentCount: (commentCount ?? 0) + 1
          }
        });
      }
    });
  };

  return (
    <>
      {/* Render Comments */}
      <div
        className={classNames('space-y-[4px] px-16', {
          'pb-[68px]':
            hasStickyCommentInput &&
            !readOnly &&
            !isReplying &&
            haveComments,
          'pb-96':
            hasStickyCommentInput &&
            !readOnly &&
            isReplying &&
            haveComments
        })}>
        {commentList?.map((comment) => {
          const commentOwnerLearnerObjId = comment?.learnerObjectId;
          const isUserCommentOwner =
            commentOwnerLearnerObjId === user?.learner?._id;
          return (
            <CommentCard
              comment={comment}
              post={post}
              user={user}
              postId={post?._id}
              communityId={communityId}
              key={comment?._id}
              onCommentDelete={onCommentDelete}
              deleteLoadingMap={deleteLoadingMap}
              fetchReplies={fetchReplies}
              getReplyInfo={getReplyInfo}
              fetchMoreReplies={fetchMoreReplies}
              onReplyDelete={onReplyDelete}
              updateAnnouncementInfo={updateAnnouncementInfo}
              allowReaction={true}
              allowReply={true}
              allowDelete={isUserCommentOwner || isAdmin}
              isCommunityAdmin={isAdmin}
              setReplyingToCommentData={setReplyingToCommentData}
            />
          );
        })}
        {isCommentLoading && <CommentCardLoading />}
        {commentData?.hasNextPage && !isCommentLoading && (
          <div className="flex px-16">
            <div
              role="button"
              tabIndex={0}
              className="mb-12 flex cursor-pointer items-center space-x-8 rounded-full px-12 py-6 hover:bg-npl-neutral-light-solid-3"
              onClick={() => {
                if (!isCommentLoading) fetchMoreComments({ postId });
              }}>
              <p className="text-button-md font-medium text-npl-text-icon-on-light-surface-primary">
                {t('show-more-comments')}
              </p>
              <Icon name="chevron-down" />
            </div>
          </div>
        )}
      </div>

      {!readOnly && (
        <AvatarCommentBox
          avatar={user?.profileImage || user?.learner?.profileImage}
          name="comment"
          value={textFieldValue}
          onChange={onTextFieldChange}
          noComments={commentCount === 0}
          onSubmit={handleCommentSubmit}
          replyingToCommentData={replyingToCommentData}
          setReplyingToCommentData={setReplyingToCommentData}
          isLoading={submitLoadingMap?.[postId]}
          hasStickyCommentInput={hasStickyCommentInput}
          isReplying={isReplying}
          isMemberFeed={true}
          commentInputRef={commentInputRef}
        />
      )}
    </>
  );
};

export default CommentSection;
